@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import 'assets/styles/variables.scss';
@import 'primeicons/primeicons.css';

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: 'Montserrat';
  --font-family: 'Montserrat';
  color: #1c1a1b;
  font-weight: 600;
}

body,
html {
  margin: 0;
  --font-family: 'Montserrat';
  font-family: 'Montserrat';
  background-color: #f9f9f9;
  font-size: 13.5px;
}

.page-title,
h3 {
  font-size: 15.5px;
  font-weight: 700;
}

.page-title {
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
}

.show-hide {
  padding: 5px 0;
  .data-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    column-gap: 7px;
  }
}

.details,
.order-detail__content {
  width: 100%;
  display: flex;
  column-gap: 3%;

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
  }

  &__left {
    flex: 1;
  }

  &__right {
    flex: 3;
    overflow: auto;
  }

  .card {
    width: 100%;
    overflow: hidden;
  }
}

.status {
  padding: 2px 10px;
  color: white;
  background-color: color('danger');
  border-radius: 15px;
  display: inline-flex;
  min-width: 90px;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  &--active {
    background-color: color('success');
  }

  &--progress {
    background-color: color('warning');
  }
}

.empty {
  width: 100%;
  text-align: center;
}

p,
input {
  font-family: 'Open Sans', sans-serif;
}

form .form-checkbox {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

form .color-picker {
  flex: 1;
  display: flex !important;
  row-gap: 7px;
  flex-direction: column;
  p-colorPicker input {
    max-width: 45px;
    height: auto;
  }
}

.container.expanded .sidebar.p-sidebar {
  width: 12rem !important;
}

.sidebar.p-sidebar {
  width: 3rem !important;
  .p-sidebar-header,
  .p-sidebar-footer {
    display: none;
  }
  .p-sidebar-content {
    padding: 2px !important;

    .p-panelmenu {
      .p-panelmenu-header {
        .p-panelmenu-header-content {
          background: none;
          border: none;
          border-radius: 0;
          .p-panelmenu-header-action {
            padding: 8px;
            font-size: 13px;
            font-family: 'Montserrat';
            font-weight: 400;
            color: white !important;

            &:focus {
              box-shadow: none !important;
            }
          }
        }
        &.p-highlight {
          .p-panelmenu-header-content {
            & > a {
              border-top: 1px solid #dee2e6 !important;
              border-top-left-radius: 6px !important;
              border-top-right-radius: 6px !important;
              border-left: 1px solid #dee2e6 !important;
              border-right: 1px solid #dee2e6 !important;
              background: color('background') !important;
              color: color('dark') !important;
            }

            &:not(.p-disabled) > a:hover {
              background: color('background');
              background-color: color('background');
              color: color('dark') !important;
            }
          }

          &:not(.p-highlight):not(.p-disabled) > a:hover {
            background: none;
            color: white;
          }
        }
      }
      .p-panelmenu-content {
        background: none;
        .p-menuitem .p-menuitem-link {
          padding: 10px;
          font-size: 13px;
          font-family: 'Montserrat';
          font-weight: 400;
          color: white;

          .p-menuitem-icon,
          .p-menuitem-text {
            color: white;
          }

          &:focus {
            box-shadow: none;
          }

          &:not(.p-disabled):hover {
            background-color: color('highlight');
          }
        }
      }
    }
  }
}

.loader__progress {
  z-index: 3500;
  position: absolute !important;
  left: calc(50% - 50px);
  top: calc(50vh - 50px);
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

form {
  &.form-b4a {
    display: flex;
    column-gap: 10px;
    row-gap: 35px;
    flex-wrap: wrap;
    padding-top: 10px;
    .p-float-label,
    .input-switch,
    .p-input-checkbox,
    .p-input-radio,
    .p-chips,
    .p-fixed-label {
      flex: 1;
      min-width: calc(50% - 30px);
      width: 100%;
      display: block;
      align-items: center;
      height: fit-content;
      ::ng-deep input {
        width: 100%;
      }
    }
    .p-input-editor {
      flex: 1;
      min-width: 100%;
      display: flex;
      flex-direction: column;
      label {
        font-size: 12px;
        margin-left: 0.75rem;
        color: #6c757d;
        transition-duration: 0.2s;
      }
      small {
        text-align: right;
      }
      p-editor {
        margin-top: 10px;
      }
    }
    .invalid {
      label {
        color: color('danger');
      }
      p-editor {
        .p-editor-toolbar {
          border-top-color: color('danger');
          border-left-color: color('danger');
          border-right-color: color('danger');
        }
        .p-editor-content {
          border-bottom-color: color('danger');
          border-left-color: color('danger');
          border-right-color: color('danger');
        }
      }
    }
    .form-group {
      flex: 1;
      display: flex;
      min-width: 100%;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      column-gap: 10px;
    }
    .p-float-label label {
      top: 25px;
    }
    .p-float-label.small-info label {
      top: 25px;
    }
    .p-float-label input:focus ~ label,
    .p-float-label input.p-filled ~ label,
    .p-float-label textarea:focus ~ label,
    .p-float-label textarea.p-filled ~ label,
    .p-float-label .p-inputwrapper-focus ~ label,
    .p-float-label .p-inputwrapper-filled ~ label {
      top: -0.75rem;
      font-size: 12px;
    }

    small {
      width: 100%;
      display: block;
      &.error {
        color: color('danger');
      }
      &.warning {
        color: color('warning');
        font-weight: bold;
      }
    }
    p-listbox,
    .p-listbox {
      width: 100%;
    }
    .p-listbox.fixed-height {
      .p-listbox-list {
        height: 39vh;
        overflow: auto;
      }
    }
    &__vertical {
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      row-gap: 5px;
    }
  }
  &.modal-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    .buttons {
      display: flex;
      column-gap: 10px;
      justify-content: flex-end;
      align-items: center;
    }
    .file-upload {
      p-button,
      p-fileUpload {
        flex: 1;
      }
      .p-float-label {
        flex: 2;
      }
    }
  }
  &.form-b4a,
  &.modal-form {
    .file-upload {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 10px;
      flex: 1;
      min-width: 45%;
      label {
        display: flex;
        flex-direction: column;
        row-gap: 7px;
        img {
          max-width: 50%;
          height: auto;
        }
      }
      a.p-button {
        max-width: fit-content;
        text-decoration: none;
        display: flex;
        column-gap: 5px;
        font-weight: 700;
      }
    }
    .full-w {
      min-width: 100%;
    }
  }
  small.danger {
    color: color('danger');
  }
  small.hint {
    text-align: right;
  }

  .p-dropdown,
  .p-multiselect,
  .p-calendar,
  .p-inputtext,
  .p-autocomplete,
  .p-inputnumber,
  .p-treeselect {
    width: 100%;

    .p-multiselect-item {
      span {
        z-index: 1000000;
        padding-left: 30px;
        margin-left: -30px;
      }
    }
  }

  .p-input-checkbox,
  .p-input-radio,
  .input-switch {
    display: flex !important;
    column-gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  p-dropdown,
  p-inputnumber,
  p-inputtext {
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding-top: 20px;

  span.p-float-label {
    max-width: 200px;
    flex: 1;
    width: 100%;
    min-width: 200px;
    .input {
      width: 100%;
      display: grid;
    }
    .pi-times--disabled {
      opacity: 0.4;
    }
  }
}

.table-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .buttons {
    column-gap: 5px;
    display: flex;
  }
}

.p-datatable.no-filter .p-datatable-header {
  justify-content: flex-end;
}

.table-overlay-panel .p-overlaypanel-content {
  display: grid;
  row-gap: 5px;
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0;
  transition: box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);

  .image {
    height: 100px;
    overflow: hidden;
    position: relative;
    background: radial-gradient(
        71.01% 100% at 80% 100%,
        color('boxFade') 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        82.32% 115.93% at 0% 100%,
        color('box') 0%,
        rgba(255, 255, 255, 0) 100%
      );

    &--bag {
      background: radial-gradient(
          71.01% 100% at 80% 100%,
          color('bagFade') 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        radial-gradient(
          82.32% 115.93% at 0% 100%,
          color('bag') 0%,
          rgba(255, 255, 255, 0) 100%
        );
    }
    &--pass {
      background: radial-gradient(
          71.01% 100% at 80% 100%,
          color('passFade') 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        radial-gradient(
          82.32% 115.93% at 0% 100%,
          color('pass') 0%,
          rgba(255, 255, 255, 0) 100%
        );
    }
    &--combo {
      background: radial-gradient(
          71.01% 100% at 80% 100%,
          color('combo') 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        radial-gradient(
          82.32% 115.93% at 0% 100%,
          color('combo') 0%,
          rgba(255, 255, 255, 0) 100%
        );
    }
    &--blue {
      background: radial-gradient(
          71.01% 100% at 80% 100%,
          color('mensFade') 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        radial-gradient(
          82.32% 115.93% at 0% 100%,
          color('mens') 0%,
          rgba(255, 255, 255, 0) 100%
        );
    }
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
    min-height: 240px;

    .user {
      text-align: center;
      text-transform: none;
      margin-top: -77px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .description {
        font-size: 18px;
        margin-top: -7px;
        text-align: center;
      }

      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.4em;
        margin: 15px 0;
      }
    }
    .avatar {
      width: 124px;
      height: 124px;
      border: 1px solid #fff;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 15px;
    }
  }

  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .card-title {
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
  }

  p-tabview {
    padding: 2px;
  }
}

.p-button-xs {
  padding: 3px 7px !important;

  .p-button-icon-left {
    font-size: 0.7rem;
  }

  .p-button-label {
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.p-button-rounded.p-button-xs {
  height: 2rem !important;
  width: 2rem !important;
}

.row-divider {
  width: 100%;
  margin: 10px auto;
  border: 1px solid lightgray;
}

.p-datatable-resizable-table > .p-datatable-tbody > tr > td {
  white-space: pre-line;
  &.large-padding {
    padding: 1rem !important;
  }
  label.input-switch {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
  &.actions {
    white-space: nowrap;
    .p-button {
      padding: 0.45rem 0.9rem !important;
      margin-right: 3px;
    }
  }
}

.data-link {
  cursor: pointer;
  color: color('box');
  text-decoration: underline;
  &--line-through {
    text-decoration: line-through;
  }
}

i.data-link {
  text-decoration: none !important;
}

.p-confirm-popup {
  &.danger .p-confirm-popup-content {
    margin-bottom: 35px;
    &::after {
      content: 'ESTA AÇÃO NÃO PODE SER DESFEITA!';
      color: color('danger');
      position: absolute;
      font-size: 16px;
      font-weight: 700;
      font-family: 'Montserrat';
      top: 35%;
      right: 20px;
    }
  }
  .p-button-label {
    margin-left: 5px;
  }
}

.p-confirm-dialog {
  &.danger .p-dialog-content {
    padding-bottom: 50px;
    &::after {
      content: 'ESTA AÇÃO NÃO PODE SER DESFEITA!';
      color: color('danger');
      position: absolute;
      font-size: 16px;
      font-weight: 700;
      font-family: 'Montserrat';
      bottom: 80px;
      left: 25px;
    }
  }
}

.p-rating {
  .p-rating-icon {
    margin: 0;
    cursor: default;
    margin-top: 10px;
  }
  .pi-star,
  .pi-star-fill {
    color: #ffc427;
  }
}

.dashboard-card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #252422;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: max-content;
  padding-bottom: 10px;
  .header {
    background-color: color('background');
    color: black;
    font-weight: 600;
    font-size: 17px;
    padding: 5px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    justify-content: center;
    width: 100%;

    &--primary {
      background-color: color('highlight');
    }

    &--warning {
      background-color: color('warning');
    }

    &--success {
      background-color: color('success');
    }

    &--danger {
      background-color: color('danger');
    }

    &--box {
      background-color: color('box');
    }

    &--bag {
      background-color: color('bag');
    }

    &--pass {
      background-color: color('pass');
    }

    &--dark {
      background-color: color('black');
    }
  }

  .value {
    padding: 8px 5px;
    width: 100%;
    text-align: center;
    padding-bottom: 0;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

    table {
      width: 100%;

      .last-month {
        position: initial !important;
        font-size: 13px;
        white-space: nowrap;
      }

      td.right {
        text-align: right;
      }
    }
  }

  .last-month {
    border-radius: 6px;
    padding: 2px;

    i {
      font-size: 12px;
    }

    &--negative {
      background-color: color('danger');
      color: white;
    }
    &--positive {
      background-color: color('success');
      color: white;
    }
  }
}

app-acquisition-dashboard p-chart canvas {
  margin: 0 auto;
}

table.xlsx-sample {
  th,
  td {
    text-align: center;
  }

  th {
    background-color: lightgray;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr > td,
  tr > th {
    padding: 5px;
    outline: 1px solid black;
  }
}

p-paginator p-dropdown {
  width: auto !important;
}

.p-carousel {
  .p-carousel-indicators .p-carousel-indicator {
    button {
      border-radius: 6px;
    }
  }
  .p-carousel.carousel__glam
    .p-carousel-indicators
    .p-carousel-indicator.p-highlight
    button {
    background-color: color('box');
  }
  .p-carousel.carousel__mens
    .p-carousel-indicators
    .p-carousel-indicator.p-highlight
    button {
    background-color: color('mens');
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (width < 960px) {
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd) {
    background: #dcdcdc;
  }
}

@media screen and (width < 768px) {
  .filters {
    margin-top: 15px;
    flex-direction: column;
    .picture {
      display: flex;
      justify-content: center;
    }
    .p-float-label {
      min-width: 100% !important;
    }
  }
  .dashboard-card {
    font-size: 15px;
  }
}

@media screen and (width < 740px) {
  .table-header {
    row-gap: 10px;
    column-gap: 10px;
    form {
      width: 100%;
      flex-wrap: wrap;
      row-gap: 10px;
      .p-float-label {
        min-width: 150px;
      }
    }
  }
}

@media screen and (width < 630px) {
  .table-header {
    h3 {
      justify-self: flex-start;
    }
    .buttons {
      justify-self: flex-end;
    }
    form {
      .p-float-label {
        max-width: 100%;
      }
      p-button,
      .p-button {
        width: 100%;
      }
    }
  }
}

@media screen and (width < 668px) {
  form.form-b4a {
    .p-float-label {
      min-width: 100%;
    }
    .buttons {
      flex-wrap: wrap;
      row-gap: 10px;
      p-button {
        min-width: 100%;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
